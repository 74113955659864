import React, { useState } from 'react';
import {
  Menu,
  Sidebar,
  Segment,
  Dropdown,
  Icon,
} from 'semantic-ui-react';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import { tokenState, userMetaDataState } from '../../ducks';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import s from './navigation.module.scss';
import { Squash as Hamburger } from 'hamburger-react';

const NavBar = ({ children }) => {
  const jwt = useRecoilValue(tokenState);
  const userData = useRecoilValue(userMetaDataState);
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Menu className={s.menu}>
        <Menu.Item className={s.burger}>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
          />
        </Menu.Item>

        <Menu.Item className={s.logo}>
          <Link to="/">
            <div className={s.wordmark}>Scriver</div>
          </Link>
        </Menu.Item>

      </Menu>
      <Sidebar.Pushable
        as={Segment}
        className={s.pushable}
      >
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          inverted
          onHide={() => {
          }}

          vertical
          visible={isOpen}
          width='thin'
        >
          <Menu.Item as='a'>
            Welcome {userData.name}
          </Menu.Item>
          <Menu.Item as='a'>
            <Link to="/books">
              <Icon name='book' />
              Books
            </Link>
          </Menu.Item>
          <Menu.Item as='a'>
            <Link to={'/annotations'}>
              <Icon name='pencil' />
              Notate
            </Link>
          </Menu.Item>
          <Menu.Item as='a'>
            {!!jwt ?
              <LogoutButton />
              :
              <LoginButton />
            }
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pusher className={s.pusher}>
          {children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>

  );
};
export default NavBar;