import { gql } from '@apollo/client';

export const ADD_ANNOTATION = gql`    
   
    mutation(
        $book_id: ID,
        $title: String,
        $excerpt: ExcerptInput
        $page: Int!,
        $text: String!
    ) {
        #        change function signature
        add_annotation(
            book_id: $book_id,
            title: $title,
            excerpt: $excerpt
            page: $page,
            text: $text
        ){
            book_id
            title
            page
            text
            #            change server configuration 
        }
    }
`;

export const GET_BOOKS = gql`
    query GetBooks {
        get_books {
            id
            title
        }
    }
`;


