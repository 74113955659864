import { createStore } from 'react-hooks-global-state';

export const persistenceKey = 'HDamoIWpGC5kRecYZA2XhdsD8o+SztKmI/zJYAUVu0eZVsce2h%';

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_jwt':
      return { ...state, jwt: action.payload };
    case 'delete_jwt':
      return { ...state, jwt: null };
    case 'set_refresh_token':
      return { ...state, refresh_token: action.payload };
    case 'delete_refresh_token':
      return { ...state, refresh_token: action.payload };
    case 'set_user_data':
      return { ...state, userData: action.payload };
    default:
      return state;
  }
};

const persistentReducer = (state, action) => {
  const mutated = reducer(state, action);
  localStorage.setItem(persistenceKey, JSON.stringify(mutated));
  return mutated;
};


const firstState = { jwt: null, refresh_token: null, userData: { } };

const initialStringFromStorage = localStorage.getItem(persistenceKey);
const initialState = initialStringFromStorage === null
  ? firstState
  : JSON.parse(initialStringFromStorage);

export const store = createStore(persistentReducer, initialState);
