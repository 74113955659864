import React from 'react';
import './App.module.scss';
import Main from './screens/Main';
import Auth from './components/Auth';
import NavBar from './components/Navigation';
import { ApolloProvider } from '@apollo/client';
import { client } from './networking';
import s from './App.module.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from 'react-router-dom';
import Annotation from './screens/Annotations';
import Books from './screens/Books';

import { atom, RecoilRoot, useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import { PersistenceObserver } from './ducks';



const re = new RegExp(/(?<=SK_).+/);


function Routes() {
  return <Switch>
    <Route path="/books">
      <Books />
    </Route>
    <Route path="/annotations">
      <Annotation />
    </Route>
    <Route path="/">
      <div>
        <Main />
      </div>
    </Route>
  </Switch>;
}

function App() {

  function myLookupOfAtomWithKey(key) {
    return atom({
      key: [key],
      default: null,
    });
  }


  const initializeState = ({ set }) => {
    for (const [key, value] of Object.entries(localStorage)) {
      const match = re.exec(key);
      if (!!match) {
        set(myLookupOfAtomWithKey(match), JSON.parse(value).value);
      }
    }
  };

  return (
    <ApolloProvider client={client}>
      <RecoilRoot initializeState={initializeState}>
        <Router>
          <NavBar>
            <Routes />
          </NavBar>
        </Router>
        <Auth />
        <PersistenceObserver />
      </RecoilRoot>
    </ApolloProvider>
  );
}




export default App;


