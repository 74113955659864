import React from 'react';
import {
  Container,
} from 'semantic-ui-react';
import MainFeed from '../Feed';
import Hero from '../../components/Hero';

const Main = () => {

  return (
      <MainFeed/>
  );
};


export default Main;