import { gql } from '@apollo/client';



export const GET_ANNOTATIONS = gql`
    query GET_ANNOTATIONS{
        get_annotations{
            book{
                title
            }
            title
            text
            excerpt {
                text
            }
        }
    }
`;

