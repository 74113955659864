import { store as _cs } from './counter';
import { store as _as } from './auth';
import { atom, useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import React from 'react';

export const counterStore = _cs;
export const authStore = _as;

export const tokenState = atom({
  key: 'jwt',
  default: null
})

export const userMetaDataState = atom({
  key:"user_data",
  default: {}
})


const PREFIX = 'SK_';

export const PersistenceObserver = () => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    for (const modifiedAtom of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      const atomLoadable = snapshot.getLoadable(modifiedAtom);
      if (atomLoadable.state === 'hasValue') {
        localStorage.setItem(
          `${PREFIX}${modifiedAtom.key}`,
          JSON.stringify({ value: atomLoadable.contents }),
        );
      }
    }
  });

  return <div>
    persisting
  </div>;
}