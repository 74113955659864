import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Popup,
  Dropdown,
  TextArea,
  Button,
  Input,
  Container,
} from 'semantic-ui-react';
import s from './annotation.module.scss';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ANNOTATION, GET_BOOKS } from './gql';
import { store } from '../../ducks/counter';

const Annotation = () => {

  const history = useHistory();

  const { loading, error, data: bookData } = useQuery(GET_BOOKS);

  useEffect(() => {
    if (!loading) {
      const tmp = bookData.get_books.map((book, indx) => {
        return {
          key: indx,
          text: book.title,
          value: book.id,
        };
      });
      setBookOptions(tmp);
    }
  }, [bookData]);


  const [addAnnotation, { data }] = useMutation(ADD_ANNOTATION);

  const [bookOptions, setBookOptions] = useState(
    [
      {
        key: 'SJW',
        text: 'White Fragility',
        value: '1233-13232-434343-23232',
      },
    ],
  );

  const [payload, setPayload] = useState({});

  const selectBook = (event, data) => {
    setPayload({ ...payload, book_id: data.value });
  };

  const handlePageNumberChange = (event) => {
    setPayload({ ...payload, page: event.target.value });
  };

  const handleExcerptChange = (event) => {
    setPayload({ ...payload, excerpt: { text: event.target.value } });
  };

  const handleMessage = (event) => {
    setPayload({ ...payload, text: event.target.value });
  };

  const handleTitleChange = (event) => {
    setPayload({ ...payload, title: event.target.value });
  };

  const submitMessage = () => {
    addAnnotation({
      variables: payload,
    })
      .then(res => {
        console.log(res);
        history.push("/")
      });
    console.log('Submiting Message');
  };

  if (loading) return 'Loading...';

  if (error) return `Error! ${error.message}`;

  return (
    <Container>
      <Form>
        <Form.Field>
          <label htmlFor="">Choose Book</label>
          <Dropdown
            placeholder="Select Book"
            fluid
            selection
            options={bookOptions}
            onChange={selectBook}
          >
          </Dropdown>
        </Form.Field>
        <Form.Field>
          <label htmlFor="">Title</label>
          <Input
            type="text"
            onChange={handleTitleChange}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="">PageNumber</label>
          <Input
            type="number"
            onChange={handlePageNumberChange}
          />
        </Form.Field>
        <Form.Field>
          <div className={s.label_group}>
            <label htmlFor="">Excerpt (optional)
            </label>
            <Popup
              content="this is optional, a an excerpt on what you're commenting on"
              trigger={<Button icon='help' />}
            />
          </div>
          <TextArea
            placeholder="tell us what you're thinking about"
            onChange={handleExcerptChange}
          />
        </Form.Field>
        <Form.Field>
          <div className={s.label_group}>
            <label htmlFor="">Message</label>
            <Popup
              content="your comment or thoughts about the selection or excerpt"
              trigger={<Button icon='help' />}
            />
          </div>
          <TextArea
            placeholder="tell us what you're thinking"
            onChange={handleMessage}
          />
        </Form.Field>
        <Button onClick={submitMessage}>Submit</Button>
      </Form>

      <div>
        {JSON.stringify(payload)}
      </div>
      <div>
        {JSON.stringify(data)}
      </div>
    </Container>
  );
};


export default Annotation;