import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { authStore, tokenState, userMetaDataState } from '../../ducks';
import { atom, useRecoilState } from 'recoil';


// auth0 spa sdk



const Auth = () => {
  const {user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [userMetadata, setUserMetadata] = useState(null);

  const [jwt, setJwt] = useRecoilState(tokenState)
  const [userData, setUserData] = useRecoilState(userMetaDataState)




  const { dispatch } = authStore;

  useEffect(() => {

    console.log("hello world")
    const getUserMetadata = async () => {
      const domain = 'scriver.us.auth0.com';

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: 'offline_access',
        });

        //recoil
        setJwt(accessToken)

        console.log(accessToken);

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });


        const data = await metadataResponse.json();

        dispatch({type:"set_user_data", payload: data})
        //recoil
        setUserData(data)

        setUserMetadata(data);

        console.log(data);

      } catch (e) {
        console.log(e.message);
      }

    };

    getUserMetadata().then(r => console.log(r));


  }, [isAuthenticated]);

  return null;

};

export default Auth;
