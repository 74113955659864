import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { persistenceKey } from '../ducks/auth';
// import { authStore } from '../../ducks'
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

import { RetryLink } from "apollo-link-retry";
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil';
import React from 'react';


const httpLink = createHttpLink({
  uri: 'https://mgkudoh658.execute-api.us-east-1.amazonaws.com/default/graphql',
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }, ...rest) => {

  console.log(headers, rest);
  const initialStringFromStorage = localStorage.getItem('SK_jwt');

  const token = JSON.parse(initialStringFromStorage);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.value}` : '',
    },
  };
});

const retryLink = new RetryLink();

const link = ApolloLink.from([
  errorLink,
  retryLink,
  authLink,
  httpLink,
]);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});


