import { gql } from '@apollo/client';

export const GET_BOOKS = gql`
    query GetBooks {
        get_books {
            id
            title
        }
    }
`;

