import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'semantic-ui-react';
import { authStore, tokenState } from '../../ducks';
import { useRecoilState } from 'recoil';

const LogoutButton = () => {

  const { dispatch } = authStore;

  const { logout } = useAuth0();

  const [jwt, setJwt] = useRecoilState(tokenState)

  return (
    <Button
      onClick={() => {
        setJwt(null)
        logout({ returnTo: window.location.origin });
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
