import React from 'react';
import {
  List,
  Container,
} from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { GET_BOOKS } from './gql';



const Books = () => {

  const { loading, error, data } = useQuery(GET_BOOKS);
  console.log(data);

  if (loading) return 'Loading...';

  if (error) return `Error! ${error.message}`;

  const handleItemClick = (title, id) => {

  }

  return <BookView bookData={data.get_books} handleClick={handleItemClick}/>;

};


export default Books;


const BookView = ({bookData, handleClick}) => {
  return (
    <Container>
      <List divided relaxed>
        {bookData.map(book => {
          return (
            <List.Item>
              <List.Header as='a' onClick={handleClick} >
                {book.title}
              </List.Header>
              <List.Content>
                {book.author}
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </Container>
  );
}

BookView.defaultProps = {
  bookData: [],
};