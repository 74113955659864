import React, { useEffect, useReducer, useState } from 'react';
import {
  Feed,
  Menu,
} from 'semantic-ui-react';
import s from './feed.module.scss';
import { useQuery } from '@apollo/client';
import { GET_ANNOTATIONS } from './gql';
import { store } from '../../ducks/counter';
import { useLocation } from 'react-router-dom';

const iniState = {};

const red = (state, action) => {
  switch (action.type) {
    case 'select_discover':
      return { selected: 'discover' };
    case 'select_clubs':
      return { selected: 'clubs' };
    default:
      return state;
  }
};

let AnnotationsFeed = function ({ annotations }) {

  if (!annotations) {
    return null;
  }
  debugger
  return <>
    {annotations.map(item => {

      return (
        <div className={s.item}>
          <div className={s.excerpt}>{item.excerpt.text}</div>
          <div className={s.text}>{item.text}</div>
        </div>
      );
    })}
  </>;
};
const MainFeed = () => {

  const { loading, error, data } = useQuery(GET_ANNOTATIONS);
  const [state, dispatch] = useReducer(red, iniState);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      dispatch({ type: 'select_discover' });
    }

    if (location.pathname === '/clubs') {
      dispatch({ type: 'select_clubs' });
    }
  }, [location]);

  // map annotation to events
  useEffect(() => {
    console.log(loading);
    if (!loading) {
      setAnnotations(data.get_annotations);
    }
  }, [data]);


  const [annotations, setAnnotations] = useState(
    [
      {
        excerpt: '',
        text: '',
      },
    ],
  );

  if (loading) return 'Loading...';

  if (error) return `Error! ${error.message}`;

  console.log(location);


  return (
    <div>
      <Menu className={s.sub_menu}>
        <Menu.Menu className={s.bottom_row}>
          <Menu.Item
            as="a"
            className={`${s.sub_nav}`}
          >
            <span className={`${state.selected === 'discover' ? s.selected : 'blah'}`}>Discover</span>
          </Menu.Item>
          <Menu.Item
            as="a"
            className={`${s.sub_nav}`}
          >
            <span className={`${state.selected === 'clubs' ? s.selected : 'blach'}`}>Your Clubs</span>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <AnnotationsFeed annotations={annotations} />
    </div>
  );
};


export default MainFeed;